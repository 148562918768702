body,
html {
    overflow-x: hidden;
}



@media (max-width: 768px) {
    
    .navbar-toggler {
        position: absolute;
        right: 15px;

        z-index: 1050;
    }

    .navbar-collapse {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .navbar-nav .nav-link {
        width: 100%;
        padding: 10px 0;
        text-align: center;
    }
}


@media (max-width: 280px)and (min-width :280px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .navbar-nav {
        text-align: center;
        width: 100%;
    }


    .navbar-toggler {
        position: absolute;
        /* top: -50px !important; */
        right: 10px !important;
        z-index: 1050 !important;
        width: auto !important;
        padding: 10px !important;
        text-align: right;
    }

    .navbar-toggler-icon {
        font-size: 30px !important;
    }


    .navbar-collapse {
        display: block;
    }
}


