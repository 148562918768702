.stream-text {
  font-size: 34px;
}

.empower-text {
  font-size: 34px !important;
}

.b2b-text {
  font-size: 15px;
}

@media (max-width: 280px) and (min-width :280px) {
  .stream-text {
    font-size: 17px !important;
  }

  .innovation_title {
    font-size: 22px !important;
  }

  .b2b-text {
    font-size: 14px !important;
  }
}

.empower-text {
  font-size: 17px !important;
}

.lap-text {
  font-size: 14px !important;
}




body,
html {
  overflow-x: hidden;
}


@media (max-width: 768px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }


  .card-img-top {
    width: 100% !important;
    height: auto !important;
  }


  .row {
    flex-direction: column !important;
  }


  .col-lg-5,
  .col-lg-6 {
    width: 100% !important;
  }

  .card-two-responsive-margin,
  .card-four-responsive-margin {
    margin-top: 10px !important;
  }


  .left-to-right-animation,
  .right-to-left-animation {
    opacity: 1 !important;
    transform: translateX(0) !important;
  }


  .col-lg-1 {
    display: none;
  }


  .navbar-toggler {
    position: relative;
    z-index: 1050;
  }

  .navbar-collapse {
    width: 100%;
  }
}


@media (max-width: 280px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }


  h2 {
    font-size: 20px !important;
  }

  p {
    font-size: 14px !important;
  }

  button {
    font-size: 14px !important;
    padding: 10px 16px !important;
    width: 100% !important;
  }


  .navbar-nav {
    text-align: center !important;
    width: 100%;
  }

  .navbar-toggler {
    width: 100%;
    padding: 10px;
  }
}






/* -------- */
@media (max-width: 991px) {
  .card-four-responsive-margin {
    margin-top: -40px;
  }
}

@media (max-width: 991px) {
  .card-two-responsive-margin {
    margin-top: -2px !important;
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.left-to-right-animation {
  opacity: 0;
  transform: translateX(-30px);
}

.right-to-left-animation {
  opacity: 0;
  transform: translateX(30px);
}

.appear-left {
  animation: slideInLeft 1s ease-out forwards;
  opacity: 1;
  transform: translateX(0);
}

.appear-right {
  animation: slideInRight 1s ease-out forwards;
  opacity: 1;
  transform: translateX(0);
}



.innovation_title {
  font-size: 44px !important;
}



@media (max-width: 576px) {
  .innovation_title {
    font-size: 24px !important;
  }

  .stream-text {
    font-size: 26px !important;
  }
  
  .empower-text {
    font-size: 26px !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .innovation_title {
    font-size: 40px;
  }

  .stream-text {
    font-size: 30px !important;
  }

  .empower-text {
    font-size: 30px !important;
  }
}