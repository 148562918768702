.bg-light {
  background-color: #f0f0f0;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
 }

.slick-dots li {
  display: inline-block;
  margin: 0 1px;
  bottom: 45px;
}

.slick-dots li button {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 0;
}

.slick-dots li button:before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(0, 117, 255, 0.90);
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.slick-dots li.slick-active button:before {
  content: '';
  border-radius: 9999px;
  background-color: #0075FF;
}
.slick-prev:before, .slick-next:before {
  color: transparent;
}



@keyframes bottomToTop {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-animate {
  animation: bottomToTop 1.2s ease-out forwards;
}

.fade-in {
  opacity: 0;
}

.appear {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-out, transform 1.2s ease-out;
}


.best_title{
  font-size: 44px !important;
}

@media (max-width: 280px) and (min-width :280px) {
  .best_title{
    font-size: 22px !important;
  }
}

@media (max-width: 576px) {
  .best_title{

    font-size: 24px !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .best_title{
    font-size: 40px;
  }
}