
  @media (max-width: 280px) and (min-width :280px) {
   .scalable-text {
      padding-top: 30px;
      font-size: 24px !important;
    }

    .container {
      padding: 0 10px;
    }

    .Home_pic {
      display: block;
      text-align: center;
    }

    .col-lg-6, .col-lg-5 {
      width: 100%;
      margin-bottom: 20px;
    }

    .btn-get {
      width: 100%;
    }

    .img-right img {
      max-width: 100%;
      border-radius: 10px;
    }

    
  }


  @media (max-width: 576px) {
    #home {
      padding-top: 60px;
    }

    .Home_pic {
      display: block;
      text-align: center;
    }

    .col-lg-6, .col-lg-5 {
      width: 100%;
      margin-bottom: 20px;
    }

    .img-right img {
      width: 100%;
    }

    .scalable-text {
      font-size: 24px;
    }
  }







.scalable-text {
  font-size: 55px; 
}


@media (max-width: 992px) {
  .scalable-text {
    font-size: 40px;
  }
}


@media (max-width: 768px) {
  .scalable-text {
    font-size: 30px;
  }
}


@media (max-width: 576px) {
  .scalable-text {
    font-size: 24px;
  }
}


@media (max-width: 989px) {


  .dot {
    display: none;
  }

  .Specializing {
    font-size: 16px;

  }

  .btn-get {
    border-radius: 8px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 16px 32px !important;


  }

  .design {
    margin-left: 30px !important;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
 

  .dot {
    display: none;
  }

  .Specializing {
    font-size: 16px !important;
    width: 428px !important;
  }

  .btn-get {
    border-radius: 8px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 16px 32px !important;
    letter-spacing: .80px;
  }

  .design {
    margin-left: -50px !important;
    max-width: 90% !important;
  }
}




.fade-in {
  opacity: 0;
  transition: opacity 1s, transform 1s;
}


.fade-in.img-right {
  transform: translateX(50px); 
}


.fade-in.text-left {
  transform: translateX(-50px);
}


.fade-in.appear {
  opacity: 1;
  transform: translateX(0);
}
