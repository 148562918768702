.services-title {
    text-align: start;
    padding-bottom: 30px;
    font-size: 44px !important;
}

.description{
    text-align: start;
}
.service-item {

    color: #141414;
}

.service-detail {

    font-weight: 500;
    font-size: 16px;
    color: #141414;
}


.vertical-line {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-left: 2px solid #D1D1D1;
    height: 90%;
    padding-left: 0px;
}

.vertical-line::after {
    content: "SERVICES";
    position: absolute;
    transform: rotate(-90deg);
    bottom: 0;
    left: -50px;
    background-color: #F6F6F6;
    padding: 0px 20px 0 5px;
    font-weight: 500;
    font-size: 16px;
    color: #0047FF;
}


@media (max-width: 728px) {
    .vertical-line {
        display: none;
    }
}

@media (max-width: 768px) {
    .tailored-text {
        display: block;

    }

    .tailored-dot {
        display: none;

    }
}

.services-title {
    display: flex;
    flex-direction: column;
    font-size: 36px;
   
}

@media (min-width: 769px) {
    .services-title {
        flex-direction: row;

    }


    .tailored-text {
        margin-left: 10px;

    }

    .tailored-dot {
        display: none;

    }

}

@media (min-width: 768px) {
    .services-title {
        font-size: 22px;
        padding-bottom: 0px;
    }

    .service-item {
        font-size: 18px;

    }

}

@media (max-width: 280px) and (min-width :280px) {
    .services-title {
        padding-top: 30px;
        font-size: 22px !important;
       ;
    }
}

@media (max-width: 576px) {
    .services-title {
        
        font-size: 24px !important;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.15s ease-out, transform 0.15s ease-out;
}

.appear {
    opacity: 1;
    transform: translateY(0);
}

