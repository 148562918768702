
.need-footer {
    background-color: #141414;
    margin-top: 50px;
    padding: 20px 15px; 
  }
  
  .need {
    font-size: 44px;
    text-align: left;
   
  }

  .need-text {
    text-align: left;
  }
  
  
  @media (min-width: 769px) and (max-width: 992px) {
    .need {
      font-size: 36px;
      text-align: left; 
      padding-top: 50px;
    }
  
    .need-text {
      text-align: left;
      margin-top: 15px;
    }
  
    .btn-touch {
      display: block;
      margin: 20px auto 0;
    }
  }
  

  @media (max-width: 576px) {
    .need {
      font-size: 28px !important;
    }
  
    .need-text {
      font-size: 12px !important;
    }
  
    .btn-touch {
      padding: 12px 20px; 
    }
  }
  

  @media (max-width: 280px) and (min-width :280px)  {
    .need {
      font-size: 24px !important;
    }
  
    .need-text {
      font-size: 10px !important;
    }
  
    .btn-touch {
      padding: 10px 15px; 
      font-size: 12px !important;
    }
  }
  