.course-heading {
  font-size: 44px !important;
}

.course-Subheading{
  font-size: 30px !important;
}

@media (max-width: 280px) and (min-width :280px) {
  .course-heading {
    font-size: 22px !important;
  }
  .course-Subheading{
    font-size: 18px !important;
  }
}

@media (max-width: 576px) {
  .course-heading {

    font-size: 24px !important;
  }

  .course-Subheading{
    font-size: 20px !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .course-heading {
    font-size: 40px  !important;
  }

  .course-Subheading{
    font-size: 28px !important;
  }
}