.ContactUs_title {
    text-align: left;
    letter-spacing: -1px;
    line-height: 1.1;
    margin-bottom: 20px;

}

.ContactUs_title span {
    color: #0047FF;
    font-weight: 600;
    font-size: inherit;
}


.card {
    line-height: 1.2;
    color: #141414;
    text-align: center;
    border: 1px solid #C3C3C3;
    border-radius: 12px;
}


.contact-info {
    list-style-type: none; 
    padding-left: 0; 
}

.contact-info li {
    margin-bottom: 5px;
}

.custom-input-height {
    height: 50px;
}

@media (max-width: 768px) {
    .ContactUs_title {
        font-size: 2.5rem;
        letter-spacing: -0.5px;
        text-align: start;

    }
}


@media (max-width: 992px) {
    .desktop-only {
        display: none;
    }
}

@media (min-width: 992px) {
    .desktop-only {
        display: block;
    }
}