.custom-owl-carousel .owl-nav .owl-prev,
.custom-owl-carousel  .owl-nav  .owl-next {
  display: flex !important;
  cursor: pointer;
  position: absolute;
  top: 80%;
}
.custom-owl-carousel   .owl-nav  .owl-prev {
  right: 120px !important;
}
.custom-owl-carousel  .owl-nav  .owl-next {
  right: 50px !important;
}
.owl-prev-icon,
.owl-next-icon {
  background-size: contain;
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}
.owl-prev-icon {
background-image: url('../Images/arrow-left.svg');
border-radius: 50px;
border: 1px solid rgba(220, 220, 220, 1);
padding: 25px;
}
.owl-next-icon {
    background-image: url('../Images/arrow-right.svg');
    border-radius: 50px;
    border: 1px solid rgba(220, 220, 220, 1);
    border-style: solid;
    padding: 25px;
}
.owl-dots {
    display: flex;
    justify-content: center; 
    gap: 10px;
    margin-top: 20px; 
    margin-bottom: 50px;
}
.owl-dot-webDevelop span {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 71, 255, 0.2);
    display: inline-block;
    border-radius: 50%;
}
.owl-dot-webDevelop.active span {
    background-color: blue;
  }

  @keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(30px); 
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.slide-up-animation {
    animation: slideUp 1s ease-out forwards;
}

  


.people_title{
  font-size: 44px !important;
}

@media (max-width: 280px) and (min-width :280px) {
  .people_title{
    font-size: 22px !important;
  }
}

@media (max-width: 576px) {
  .people_title{

    font-size: 24px !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .people_title{
    font-size: 40px;
  }
}

  
 
 